import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex, Button, Link } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'
import Emoji from '@widgets/Emoji'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  const authors = useBlogAuthors()

  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Us'
            subheader='Về tụi mình, Outdoors by GenkiHub'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            <Link href='https://m.me/genkihub' target='_blank'>
              <Button sx={styles.button}>
                Liên hệ
              </Button>
            </Link>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='Câu chuyện'>
                <Text variant='p'>
                  Được tổ chức vào năm 2020 bởi các thành viên người Việt Nam sống tại Tokyo, Nhật Bản.
                  Nằm trong hệ sinh thái của GenkiHub, một cộng đồng về sức khoẻ thế chất, tinh thần,
                  hướng tới sự phát triển toàn diện về TÂM-THÂN-TRÍ và đặt yếu tố cân
                  bằng trong cuộc sống lên hàng đầu.
                </Text>
                <Text variant='p'>
                  Outdoors là trang thông tin được tạo ra, vận hành bởi người Việt và hướng tới đối tượng
                  chính là người Việt.
                </Text>
                <Text variant='p'>
                    Outdoors luôn mong muốn có thể tiếp cận, trở nên có ích với cộng đồng người Việt trên mọi miền thế giới.
                </Text>
              </Section>
              <Divider />
              <Section title='Sứ mệnh'>
                <Card variant='paper'>
                  Truyền cảm hứng tham gia vào các hoạt động ngoài trời thông qua những câu chuyện,
                  chia sẻ nhằm góp phần xây dựng một cộng đồng năng động <Emoji symbol="🔥" label="fire" />
                  khoẻ mạnh <Emoji symbol="💪" label="flexed-biceps" />
                </Card>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Nội dung'>
                <Card variant='paper'>
                  Các nội dung được cung cấp bởi Outdoors được đóng góp bởi các
                  thành viên bao gồm các chủ đề chính:
                  <ul>
                    <li>Leo núi</li>
                    <li>Đạp xe</li>
                    <li>Chạy bộ</li>
                    <li>Cắm trại</li>
                  </ul>
                  Những kinh nghiệm hay trải nghiệm cá nhân với mục đích chia sẻ đều
                  được welcome tại Outdoors.
                </Card>
              </Section>
              <Divider />
              <Section title='Hoạt động'>
                <Card variant='paper'>
                  Các sự kiện chia sẻ kinh nghiệm, hoạt động dã ngoại thường xuyên được
                  Outdoors tổ chức và chào đón tất cả các thành viên tham gia.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Chúng tôi là ai?'
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map((author) => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
        </Main>
      </Stack>
    </>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
